<template>
  <div>
    <question-view
      class="mb-6"
      v-for="question in questions"
      :key="question.id"
      :form-id="formId"
      :question="question"
      :answer="answers[question.id]"
      :v="v && v[question.id]"
      @change="answerSet(question.id, $event)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import QuestionView from './QuestionView';
export default {
  name: 'QuestionsView',
  components: { QuestionView },
  props: {
    formId: { type: String, required: true },
    questions: { type: Array, required: true },
    answers: { type: Object, required: true },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  methods: {
    answerSet(id, answer) {
      const answers = {
        ...this.answers,
        [id]: answer,
      };
      this.$emit('change', answers);
    },
  },
};
</script>
