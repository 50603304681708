<template>
  <div
    class="w-full rouded bg-white p-4 md:rounded shadow md:hover:shadow-xl"
    :class="
      v.$error ? 'bg-red-50 border-t border-b md:border border-red-700' : ''
    "
  >
    <component
      :is="'q-' + question.type"
      :form-id="formId"
      :question="question"
      :answer="answer"
      :v="v"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import { fromPairs } from 'ramda';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrInputSelect from '@/sqrd-ui/SqrInputSelect';
import SqrToggle from '@/sqrd-ui/SqrToggle';

const comps = [
  'text',
  'textarea',
  'number',
  'radio',
  'checkbox',
  'select',
  'file',
  //
  'header',
  'name',
  'email',
  'address',
  //
  'product',
  //
  'scale',
  'grid-radio',
  'grid-checkbox',
  'date',
  'time',
];
const viewComps = comps.map(name => [
  `q-${name}`,
  () => import(`./parts/${name}/View`),
]);
export default {
  name: 'QuestionView',
  components: {
    SqrButton,
    SqrInputText,
    SqrInputSelect,
    SqrToggle,
    ...fromPairs(viewComps),
  },
  props: {
    formId: { type: String, required: true },
    question: { type: Object, required: true },
    answer: { type: [Object, String, Number, Array] },
    disabled: { type: Boolean },
    v: { type: Object, default: () => ({}) },
  },
};
</script>
