<template>
  <div>
    <div class="mx-auto max-w-xl mt-8">
      <div v-if="inquiry" class="mb-16">
        <questions-view
          :form-id="inquiry.id"
          :questions="inquiry.model.questions"
          :answers="inquiry.answers || {}"
          @change="fieldSet('answers', $event)"
          :disabled="disabled"
        />
        <div class="bg-white py-4 shadow rounded">
          <table class="w-full">
            <tbody>
              <tr
                v-for="rcpt in inquiry.model.recipients"
                :key="rcpt.id"
                class="hover:bg-gray-50 text-sm leading-5 font-normal"
                :class="disabled ? 'text-gray-400' : 'text-gray-700'"
              >
                <td class="px-4 py-1">
                  <input
                    v-model="recipientIds"
                    :value="rcpt.id"
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    :disabled="disabled"
                  />
                </td>
                <td class="py-1">{{ rcpt.company }}</td>
                <td class="py-1">{{ rcpt.name }}</td>
                <td class="py-1">{{ rcpt.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs } from 'ramda';
const { compile } = require('handlebars');

import broker from '../broker';

import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import QuestionsView from '@/sqrd-forms/QuestionsView';

import nanoRef from '@/utils/nanoRef';
import stamp from '@/utils/stamp';

function buildData(questions, answers) {
  const pairs = questions.map(question => {
    let text = '';
    switch (question.type) {
      case 'radio':
      case 'select':
        text = answers[question.id]?.label ?? '';
        break;
      case 'checkbox':
        text = answers[question.id]?.map(e => '– ' + e.label).join('\n') ?? '';
        break;
      case 'file':
      case 'grid-radio':
      case 'grid-checkbox':
        text = '(unsupported)';
        break;
      // text, textarea, scale, date, time
      default:
        text = answers[question.id] ?? '';
    }
    return [question.id, text];
  });

  const transformed = fromPairs(pairs);

  return {
    ...answers,
    ...transformed
  };
}

export default {
  name: 'InquiryFill',
  mixins: [broker],
  components: { SqrButton, SqrErrorBanner, QuestionsView },
  props: {
    iid: String
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('inquiry', { inquiry: 'doc' }),
    ...mapState('inquiry', ['loading', 'loadError', 'path']),
    recipientIds: {
      get() {
        return this.inquiry?.recipientIds ?? [];
      },
      set(value) {
        this.fieldSet('recipientIds', value);
      }
    },
    disabled() {
      return this.inquiry?.status !== 'draft';
    }
  },
  data() {
    return {
      sending: false,
      sendError: null
    };
  },
  mounted() {
    const bid = this.bid;
    const iid = this.iid;
    this.sub({
      path: `brokers/${bid}/inquiries/${iid}`
    });
  },
  methods: {
    ...mapActions('inquiry', ['sub', 'unsub']),
    ...mapActions('updater', ['update']),
    ...mapActions('remover', ['remove']),
    async modelRefresh() {
      const path = this.inquiry?.model?.path;
      if (path) {
        const snap = await this.$db()
          .doc(path)
          .get();
        const model = { ...snap.data(), path, id: snap.id };
        this.fieldSet('model', model);
      }
    },
    fieldSet(name, value) {
      this.update({ path: this.path, doc: { [name]: value } });
    },
    async clone() {
      const name = prompt('Nom de la nouvelle demande ?');
      if (!name) return;
      const created = stamp();
      try {
        this.cloning = true;
        const bid = this.bid;
        const inquiry = {
          ...omit(['id', 'path', 'name'], this.inquiry),
          name,
          created,
          updated: created
        };
        const res = await this.$db()
          .collection(`brokers/${bid}/inquiries`)
          .add(campaign);
        this.$router.push({
          name: 'inquiry',
          params: { bid, iid: res.id }
        });
      } catch (error) {
        alert('erreur lors de la copie');
        return Promise.reject(error);
      } finally {
        this.cloning = false;
      }
    },
    async removeConfirm() {
      if (confirm('Supprimer la demande ?')) {
        await this.remove({ path: this.path });
        this.$router.back();
      }
    },
    async send() {
      try {
        this.sendError = null;
        this.sending = true;
        const bid = this.bid;

        const data = buildData(
          this.inquiry?.model?.questions ?? [],
          this.inquiry.answers ?? {}
        );
        const recipients = this.inquiry.recipientIds
          .map(id => {
            return this.inquiry?.model?.recipients?.find(r => r.id === id);
          })
          .filter(v => Boolean(v));

        const subjectT = compile(this.inquiry?.model?.subject ?? '');
        const textT = compile(this.inquiry?.model?.text ?? '');

        const batch = this.$db().batch();
        const col = this.$db().collection(`brokers/${bid}/emails`);
        recipients.forEach(recipient => {
          batch.set(col.doc(), {
            to: `${recipient.name} <${recipient.email}>`,
            replyTo: this.user?.email ?? '',
            message: {
              subject: subjectT(data),
              text: textT(data)
            }
          });
        });

        const name = subjectT(data);
        batch.update(this.$db().doc(this.path), {
          name,
          status: 'sent',
          updated: stamp()
        });

        await batch.commit();
      } catch (error) {
        this.sendError = error;
        return Promise.reject(error);
      } finally {
        this.sending = false;
      }
    }
  },
  metaInfo() {
    const name = this.inquiry?.name;
    return {
      title: `${name} - demande d'offre`
    };
  }
};
</script>
